import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { styled } from "styled-components";
import { db } from "../../utils/firebase";
import { randomString } from "../../utils/random";

export default function Warn2({ now, next }) {
  const handleSubmit = () => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") {
        obj = {
          ...o,
          batman: 0,
          kill_to_save: 0,
          kill_gangster: 0,
          ignore_to_save_boat: 0,
          kill_prisoner: 0,
          kill_newborn: 0,
          total: 7,
        };
        const docRef = doc(db, "Eng102", randomString());
        setDoc(docRef, obj, { merge: true });
      }
    }
    sessionStorage.res = JSON.stringify(obj);
    sessionStorage.step = 20;
    next(20);
  };
  return (
    <Container>
      <h2>Thank you for making this far.</h2>
      <h2>
        Next questions are optional But very much appreciated to participate,
        and will make the feedback more accurate and reliable.
      </h2>
      <p>
        If you do not wish to answer anymore questions press the submit button
        or continue.
      </p>
      <div className="buttons">
        <button
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </button>

        <button
          onClick={() => {
            sessionStorage.step = now + 1;
            next(now + 1);
          }}
        >
          Continue
        </button>
      </div>
    </Container>
  );
}

const Container = styled.section`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 1.3rem;
  h2,
  p {
    text-align: center;
  }
  & > .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    justify-content: center;
    & > button {
      background: linear-gradient(100deg, #2d423b 0%, #375148 100%);
      border: none;
      outline: none;
      color: #ccc;
      font-size: inherit;
      padding: 12px min(30%, 100px);
      /* margin-left: auto; */
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 5px 5px 10px #121a18, -5px -5px 10px #121a18;
      &:disabled {
        filter: grayscale(100%);
        box-shadow: none;
      }
    }
  }
`;
