import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function SelectedListFall2024() {
  const [Data, setData] = useState([]);

  // Constants

  useEffect(() => {
    const Selected = `1	Abdullah Al Shafi	2024-3-66-071	+8801839034785	abdullahalshafi21@gmail.com
2	Abdullah Bin Taslim	2024-3-80-092	+8801839393134	taslim.abdullah4949@gmail.com
3	Abu Siddik	2023-3-10-019	+8801770635953	abusiddik.fahim6@gmail.com
4	Afshana Mimi	2023-2-10-138	+8801781810150	mimiafsan977@gmail.com
5	Aftab Hossain	2024-3-66-008	+8801791118900	riyad.aftab@gamil.com
6	Ahona Reza	2024-3-33-107	+8801700771669	ahonareza529@gmail.com
7	Anisa Ahsan	2024-3-33-045	+8801914720557	ahsananisa8@gmail.com
8	Ankita Mutsuddy	2023-3-30-097	+8801534759010	2023-3-30-097@std.ewubd.edu
9	Argha Saha	2024-3-60-318	+8801874332338	arghasaha3020@gmail.com
10	Ashef Ayman Khan	2024-3-66-033	+8801733499175	ashefayman@gmail.com
11	Badhon Bacchu	2023-3-60-482	+8801775738371	bbadhonbacchu@gmail.com
12	Eshrak Ahmed Eshti	2024-3-77-072	+8801894821803	eshrakahmedeshti0@gmail.com
13	Fahim Ahmed Shahariar	2024-3-66-007	+8801749377880	fahimahmedshahariar@gmail.com
14	FARHANA AKTER Sneha	2024-3-66-059	+8801577166680	farhanaliliput1525@gmail.com
15	Fawziyya Tabassum	2024-3-66-049	+8801680172000	fawziyya2005@gmail.com
16	Foysal Ahammed	2024-1-40-134	+8801540080591	foysalahammedfahad15@gmail.com
17	hafsa rezwona	2024-3-60-185	+8801976557678	hafsahumayra96@gmail.com
18	Isabah Galib Khan	2023-3-30-032	+8801303719484	isabahgalibkhan@gmail.com
19	Jahidul Islam Riyasat	2024-1-10-014	+8801632762330	sumit.bd450@gmail.com
20	Jannatin Tazri	2024-3-66-037	+8801741893622	jtazri70@gmail.com
21	Jannatul Ferdows Sweety	2024-1-40-058	+8801759067632	ferdowssweety@gmail.com
22	Mahin Ahmed Zarif	2024-3-70-062	+8801756584458	zarifahmed477@gmail.com
23	Mashudur Rahman Mirah	2024-3-66-036	+8801876341196	mashudurrahmanmiraj@gmail.com
24	Masrura Islam Chowdhury	2024-3-66-046	+8801879092654	masruraachow@gmail.com
25	MD Mahmudur Rahman Khan Talha	2024-3-22-093	+8801567834414	bd.talhakhan915@gmail.com
26	MD Mehedi Hasan Sagor	2024-3-66-029	+8801782220889	mehedihasansagor009@gmail.com
27	MD Rezwan Ul Islam	2023-2-40-004	+8801786719218	rezwanrafat26@gmail.com
28	MD Rifat Khan	2024-1-30-019	+8801962426668	refat.rk369@gmail.com
29	MD. Abdulla Al Asif	2024-1-42-012	+8801763195190	muhammadabdullahalasif20@g-mail.com
30	Md. Abu Kawsar Nayeem	2024-3-77-189	+8801735294023	2024-3-77-189@std.ewubd.edu
31	MD. Saif Sikder	2024-3-10-073	+8801609902424	saifsikder980@gmail.com
32	Md. Sami Khan	2024-1-30-035	+8801515653405	samikhan8825@gmail.com
33	Mithila Farzana Suddho	2023-2-10-250	+8801623623404	farzanamithila362@gmail.com
34	Mohammad Nahin Ahmed	2023-2-30-038	+8801772460963	nahinahmed840@gmail.com
35	Mohammad Saad Islam	2024-3-60-411	+8801670870047	saadislampial@gmail.com
36	Most. Nishat Jahan Nur-E	2024-3-70-046	+8801708582994	nishatnuri003@gmail.com
37	Mostofa Tanvir	2024-1-66-049	+8801915091357	mdahmeduuu@gmail.com
38	Mst Faria Tabasum Mouly	2023-2-66-036	+8801890403017	mfariatabassum@gmail.com
39	Muntaseer Anjum	2024-3-66-061	+8801637787715	muntaseeranjum123@gmail.com
40	Nafis Bin Nadim	2024-3-66-057	+8801876536373	nafisbinnadim@gmail.com
41	Nafisa Akter Anika	2024-1-10-189	+8801776061352	nafisaakterewu@gmail.com
42	Najhat Noorin Saima	2024-1-60-255	+8801575700995	najhat015@gmail.com
43	Nusrat Jahan Dipty	2023-3-77-114	+8801846128836	bindu128836@gmail.com
44	Owafia Sadika	2023-3-40-049	+8801645224390	wafiasadika419@gmail.com
45	Protyoy Talukder Utsaw	2024-1-66-015	+8801784381324	protyoytalukder11@gmail.com
46	Raima Reza	2024-3-77-011	+8801919619195	raima.reza2004@gmail.com
47	Raiyan Tabassum	2024-3-11-072	+8801531269572	raiyantabassum27@gmail.com
48	Riffat Ara Zaman	2024-3-10-049	+8801974881778	2023-3-10-049@std.ewubd.edu
49	S M Adnan Ahmed	2024-3-10-686	+8801717595260	adnanasami260@gmail.com
50	S M Shihab Raduan	2024-3-80-076	+8801859970800	shihabraduan@gmail.com
51	Sabrina Uddin	2023-2-40-064	+8801572563809	uddinsabrina0@gmail.com
52	Sadia Afrin Sanjana	2024-1-80-038	+8801648060149	sanjanaafrin628@gmail.com
53	Sadia Islam	2022-3-22-026	+8801300287613	sadiaislamhema123@gmail.com
54	Samania Zannati	2024-3-77-078	+8801320578151	samaniazannati@gmail.com
55	Samia Islam anika	2024-3-66-014	+8801307758446	annikaaislam@gmail.com
56	Sanjida Tabassum Ara Enna	2024-3-42-072	+8801575422154	sanjidaenna1993@gmail.com
57	Saptarshi Das	2024-3-77-098	+8801752661886	purbad619@gmail.com
58	Sara Nujhat	2024-3-11-081	+8801314200570	saranujhat@gmail.com
59	Sayma Kabir Roza	2023-3-30-031	+8801770236858	kabirroza0@gmail.com
60	Shadman Alvi	2023-3-60-364	+8801715647799	Shadonly258@gmail.com
61	Shafee Firoz	2024-3-77-123	+8801707545677	safifirozewu@gmail.com
62	Shahrin Ahmed	2024-1-70-027	+8801630740797	shahrinahmed11@gmail.com
63	Shanjida Akter	2023-3-40-102	+8801935182623	Sanjeedaislam813@gmail.com
64	Shruti Ghosh Vobani	2024-3-10-336	+8801616785239	shrutighosh341@gmail.com
65	Sulaem Faizah	2023-3-77-089	+8801552316694	sulaemfaizah@gmail.com
66	Sumaiya islam	2024-3-60-031	+8801533312221	sumaiyaislamaddrita@gmail.com
67	Sylvia Sanjida Haque	2024-3-66-075	+8801705610799	sylviahaque@gmail.com
68	Tabassum Kibria Tithi	2024-3-77-134	+8801843235910	tabassumkibriatithi@gmail.com
69	Tahmid al abrar	2024-3-77-175	+8801856883793	tahsintaa2@gmail.com
70	Tahmid Tazmain Mostafee	2024-3-27-047	+8801892568154	tahmidtazmostafee@gmail.com
71	TANBIN HAQUE TASHDID	2024-3-80-055	+8801730159906	mujeeb.villagio@gmail.com
72	Tanjimul Islam Tamim	2023-3-60-448	+8801877146040	tamim220744@gmail.com
73	Tasme Afroz Anika	2024-3-40-055	+8801316118190	2024-3-40-055@std.ewubd.edu
74	Tasnia Mollik Taaha	2024-3-66-027	+8801911502399	tasniamollick8@gmail.com
75	Umma Sumaiya Masroor	2024-3-42-078	+8801318089685	sumaiyamasroor94@gmail.com
76	Umme Habiba Asrafi	2022-2-10-096	+8801777932558	ummehabibaashu123@gmail.com
77	Urbana Shaila	2024-3-70-057	+8801576774205	urbanashaila@gmail.com
78	Aiman Saif	2024-3-70-049	+8801883445454	mraiman662@gmail.com
79	MD.Mahbubur Rahman Sami	2023-3-77-113	+8801704920322	evanadnan27@gmail.com
80	Mashiat Jahan	2023-2-22-028	+8801318624433	247748mashiatjahan@gmail.com
81	Most Afsana Afrin Mim	2023-3-10-392	+8801747487088	2023-3-10-392@std.ewubd.edu
82	Fagun Das	2024-3-66-026	+8801301075582	fagundasdrubo@gmail.com`;
    function ProcessData() {
      let arrA = Selected.split("\n");
      let arr = [];
      for (let el of arrA) {
        let arrB = el.split("\t");
        let obj = {
          serial: arrB[0],
          name: arrB[1],
          id: arrB[2],
          phoneNo: arrB[3],
          email: arrB[4],
        };
        arr.push(obj);
      }
      // let arr2d = []
      // let as = [25,25,25,25,25,25,25,13]
      // let abc = 0;
      // for(let an of as){
      //   let arf = []
      //   for (let i=0;i<an;i++){
      //     arf.push(arr[abc]);
      //     abc++;
      //   }
      //   arr2d.push(arf)
      // }
      // setData(arr2d);
      setData(arr);
    }

    ProcessData();
  }, []);

  return (
    <>
      <Container>
        <div className="body">
          <div className="">
            {/* {Data?.map((el) => `${el.id}@std.ewubd.edu`)?.join(", ")}
            {Data?.map((el) => el.email)?.join(", ")} */}
            {/* {[
              ...new Set([...Data?.map((el) => el?.id?.split("-")?.[2])]),
            ]?.join(", ")} */}
          </div>
          <table>
            <thead>
              <tr>
                {/* <th>Serial</th> */}
                <th>Name</th>
                <th>ID</th>
                {/* <th>Phone No</th>
                <th>Email</th> */}
              </tr>
            </thead>
            <tbody>
              {React.Children.toArray(
                Data.map((item) => (
                  <tr>
                    {/* <td>{item?.serial}</td> */}
                    <td>{item?.name}</td>
                    <td>{item?.id}</td>
                    {/* <td>
                      <a href={`tel:${item?.phoneNo}`}>{item?.phoneNo}</a>
                    </td>
                    <td>
                      <a href={`mailto:${item?.email}`}>{item?.email}</a>
                    </td> */}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <footer className="footer">
          <p>
            &copy; EWUDC 2024 | Developed by{" "}
            <a
              href="https://www.facebook.com/AkhlakHossainJim03/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Akhlak
            </a>
          </p>
        </footer>
      </Container>
    </>
  );
}

const Container = styled.main`
  font-family: "Lato", sans-serif;
  min-height: 100dvh;
  width: min(1440px, 100%);
  margin: 0 auto;
  background: url(/media/ewu/fall24/left.svg), url(/media/ewu/fall24/right.svg);
  background-size: 180px auto, 180px auto;
  background-position: top right, bottom left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  & > .body {
    display: flex;
    flex-direction: column;
    padding: 56px 24px;
    & > table {
      margin: 0 auto;
      border-collapse: collapse;
      background-color: transparent;
      & > * {
        & > tr {
          & > * {
            border: 1px solid #000;
            padding: 4px 12px;
            background-color: transparent;
            font-weight: 500;
            font-size: 1rem;
          }
        }
      }
    }
  }
  & > .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    padding: 12px 24px;
    @media print {
      display: none;
    }
    & > p {
      & > a {
        font-weight: 600;
        font-style: italic;
        color: inherit;
      }
    }
  }
`;
