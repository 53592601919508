import React, { useState } from "react";
import { styled } from "styled-components";
import OptionBox from "../OptionBox";
import { db } from "../../utils/firebase";
import { doc, setDoc } from "firebase/firestore";
import { randomString } from "../../utils/random";

export default function StoryQ({ now, next, bangla, data, last = false }) {
  const [Ans, setAns] = useState();

  const handleNext = () => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") obj = o;
      else obj = {};
    } else obj = {};
    obj = {
      ...obj,
      [data?.key]: Ans,
    };
    sessionStorage.res = JSON.stringify(obj);
    sessionStorage.step = now + 1;
    next(now + 1);
    setAns();
    window.scrollTo(0, 0);
  };
  const handleSubmit = () => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") obj = o;
      else obj = {};
    } else obj = {};
    obj = {
      ...obj,
      [data?.key]: Ans,
      total: 13,
    };
    const docRef = doc(db, "Eng102", randomString());
    setDoc(docRef, obj, { merge: true });
    sessionStorage.res = JSON.stringify(obj);
    sessionStorage.step = now + 1;
    next(now + 1);
    window.scrollTo(0, 0);
  };
  return (
    data && (
      <Container>
        <h4>{data?.queB && bangla ? data?.queB : data?.que}</h4>
        <OptionBox
          options={data?.op}
          selected={Ans}
          set={setAns}
          size="small"
        />
        <button
          className="eng102_next"
          disabled={!Ans}
          onClick={last ? handleSubmit : handleNext}
        >
          {last ? "Submit" : "Next"}
        </button>
      </Container>
    )
  );
}
const Container = styled.section`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
