import React from "react";
import { styled } from "styled-components";

export default function Warn1({ now, next }) {
  return (
    <Container>
      <h2>Thank you for making this far.</h2>
      <h3>
        Next questions are like stories, and there is no right or wrong
        answer(Never was) Just mark what ever you think you would do.
      </h3>
      <button
        onClick={() => {
          sessionStorage.step = now + 1;
          next(now + 1);
        }}
      >
        Next
      </button>
    </Container>
  );
}

const Container = styled.section`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 1.3rem;
  h2,
  h3,
  p {
    text-align: center;
  }
  & > button {
    background: linear-gradient(100deg, #2d423b 0%, #375148 100%);
    border: none;
    outline: none;
    color: #ccc;
    font-size: inherit;
    padding: 12px min(30%, 100px);
    margin: 0 auto;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 5px 5px 10px #121a18, -5px -5px 10px #121a18;
    &:disabled {
      filter: grayscale(100%);
      box-shadow: none;
    }
  }
`;
