import React from "react";
import styled from "styled-components";

export default function LegendTextarea({
  label,
  placeholder = "",
  required = true,
  value,
  setValue,
}) {
  return (
    <div style={{ width: "100%" }}>
      <div
        className="h4"
        style={{ opacity: "0", maxWidth: "95%", marginBottom: "-0.9lh" }}
      >
        {label}
      </div>
      <Container>
        <label
          className="h4"
          htmlFor={label
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {label}
        </label>
        <textarea
          name={label
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={label
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={required}
          placeholder={placeholder}
          className={``}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></textarea>
      </Container>
    </div>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #a6a4a1;
  border-radius: 8px;
  & > label {
    position: absolute;
    max-width: 95%;
    left: 1rem;
    top: 0;
    transform: translateY(-80%);
    background-color: var(--aj-white, #ffffff);
    padding: 0 0.5rem;
    font-size: 1rem;
    text-align: left;
  }
  & > textarea {
    width: 100%;
    min-height: 120px;
    field-sizing: content;
    padding: 16px;
    padding-top: 24px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #000;
    resize: none;
    &:invalid,
    & > .error {
      color: #da1f26;
    }
  }
`;
