import React from "react";
import { styled } from "styled-components";

export default function TAndCCon({ setShow, agree }) {
  const data = `By participating in this research survey on decision-making tendencies through utilitarian and deontological lenses, you agree to the following terms and conditions:
-Eligibility: Participants must be at least 18 years old to take part in the survey.
-Voluntary Participation: Participation in this survey is entirely voluntary, and you have the right to withdraw at any time without providing a reason.
-Confidentiality: Your responses will be treated with the utmost confidentiality. All data collected will be anonymized and stored securely in accordance with applicable data protection regulations.
-Data Use and Analysis: The information collected from this survey will be used solely for research purposes. Your responses will be analyzed in aggregate form, and no individual participant will be personally identifiable in any reports or publications resulting from this study.
-Research Ethics: This study has been approved by the relevant ethics review board, ensuring that it adheres to ethical guidelines and safeguards the rights and well-being of participants.
-Accuracy of Information: Please provide accurate and honest responses to the best of your knowledge. Your input will contribute to the integrity and validity of the research findings.
-Potential Risks: While participating in this survey poses minimal risks, it is important to note that reflecting on personal experiences or discussing certain topics may evoke emotional responses. If you feel distressed during the survey, please consider seeking appropriate support or counseling services.
-Data Retention: Your data will be retained for a specific period as required by applicable laws and regulations. After this period, all data will be securely destroyed.
-Contact Information: If you have any questions, concerns, or requests regarding this research study, you may contact the research team at [provide contact details].
By proceeding with the survey, you indicate that you have read and understood the terms and conditions outlined above and consent to participate in the research study on decision-making tendencies.
Thank you for your participation!
#ResearchSurvey #TermsAndConditions #Ethics #DataPrivacy`;
  return (
    <Container>
      <h1>Terms and Conditions:</h1>
      {data &&
        React.Children.toArray(
          data
            .split("\n")
            .map((el) =>
              el.charAt(0) === "-" ? (
                <p>&nbsp;&nbsp;*&nbsp;&nbsp;{el.replace("-", "")}</p>
              ) : (
                <p className="gen">{el}</p>
              )
            )
        )}
      <div className="buttons">
        <button className="no" onClick={() => setShow(false)}>
          Deny
        </button>
        <button
          className="yes"
          onClick={() => {
            agree(true);
            setShow(false);
          }}
        >
          Accept
        </button>
      </div>
    </Container>
  );
}
const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 580px) {
    padding: 24px;
  }
  & > .gen {
    margin: 4px 0;
  }
  & > .buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 24px;
    & > button {
      padding: 12px 34px;
      border: none;
      outline: none;
      font-size: inherit;
      border-radius: 12px;
      cursor: pointer;
      &.no {
        background-color: #f4d485;
      }
      &.yes {
        box-shadow: 5px 5px 10px #121a18, -5px -5px 10px #121a18;
        color: #ccc;
        background: #2d423b;
      }
    }
  }
`;
