import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import LegendInput from "../../components/input/LegendInput";
import LegendTextarea from "../../components/input/LegendTextarea";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { randomStringWithDate } from "../../utils/random";

export default function RecruitmentFall2024() {
  const [Stepper, setStepper] = useState(1);
  const [FormShow, setFormShow] = useState(false);
  const [TimeOver, setTimeOver] = useState(false);

  // Constants
  const Options = [
    "Debater",
    "Organizer",
    "Video Editor",
    "Graphic Designer",
    "Content Writer",
  ];

  // form Info
  // ----step 1
  const [Name, setName] = useState();
  const [ID, setID] = useState();
  const [PhoneNo, setPhoneNo] = useState();
  const [Email, setEmail] = useState();
  const [Department, setDepartment] = useState();
  const [Semester, setSemester] = useState();
  // ----step 2
  const [Fields, setFields] = useState([]);
  // ----step 3
  const [Motivation, setMotivation] = useState();
  const [Experience, setExperience] = useState();
  const [Language, setLanguage] = useState();
  const [ClubAffiliations, setClubAffiliations] = useState();
  const [Clubs, setClubs] = useState();
  // ----step 4
  const [TypeAnswered, setTypeAnswered] = useState();
  const [Q1, setQ1] = useState();
  const [Q1E, setQ1E] = useState();
  const [Q2, setQ2] = useState();
  const [Q2E, setQ2E] = useState();
  const [Q3, setQ3] = useState();

  useEffect(() => {
    if (Fields?.includes("Debater")) {
      setTypeAnswered("Debater");
    } else if (Fields?.includes("Organizer")) {
      setTypeAnswered("Organizer");
    } else {
      setTypeAnswered("Creatives");
    }
  }, [Fields.length]);

  useEffect(() => {
    const to = setTimeout(() => setFormShow(true), 1000);
    return () => clearTimeout(to);
  }, []);

  useEffect(() => {
    const startTime = new Date("October 27, 2024 12:00:00 AM").toLocaleString(
      [],
      {
        timeZone: "Asia/Dhaka",
      }
    );
    const endTime = new Date("October 31, 2024 12:00:00 AM").toLocaleString(
      [],
      {
        timeZone: "Asia/Dhaka",
      }
    );
    var asiaDhaka = new Date().toLocaleString([], { timeZone: "Asia/Dhaka" });
    console.log(asiaDhaka, endTime);

    if (asiaDhaka < endTime) {
      setTimeOver(true);
    }
  }, []);

  function handleClick(value) {
    let arr = [...Fields];
    if (arr.indexOf(value) >= 0) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setFields(arr);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let obj = {
      name: Name,
      iD: ID,
      contact: PhoneNo,
      email: Email,
      department: Department,
      semester: Semester,
      departments: Fields,
      motivation: Motivation,
      experience: Experience,
      language: Language,
      clubAffiliation: ClubAffiliations,
      otherClubs: Clubs,
      typeAnswered: TypeAnswered,
      q1: Q1,
      q1e: Q1E,
      q2: Q2,
      q2e: Q2E,
      q3: Q3,
    };
    if (Stepper === 4) {
      try {
        const docRef = doc(db, "EWUDC-Fall-2024", randomStringWithDate());
        setDoc(docRef, JSON.parse(JSON.stringify(obj)), { merge: true });
        setStepper(5);
      } catch (error) {
        alert(JSON.stringify(error));
      }
    } else if (Stepper === 1) {
      setStepper(2);
    } else if (Stepper === 2) {
      setStepper(3);
    } else if (Stepper === 3) {
      setStepper(4);
    }
    console.log(obj);
  }

  return (
    <>
      <Helmet>
        <title>Welcome to EWUDC | Recruitment Fall 2024</title>
        <meta
          name="description"
          content="Embracing bold ideas and daring to challenge the status quo with clarity and purpose; engaging in debate gives you the opportunity to craft your arguments with strategy and precision, anticipating the counterpoints of your opponent."
        />
        <meta property="og:title" content="EWUDC | Recruitment Fall 2024" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/bg/Eng102.jpg" />
        <meta
          property="og:url"
          content="https://forms.ahjim.com/ewudc/recruitment-fall-2024"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:description"
          content="Embracing bold ideas and daring to challenge the status quo with clarity and purpose; engaging in debate gives you the opportunity to craft your arguments with strategy and precision, anticipating the counterpoints of your opponent."
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Container>
        <div className="body">
          {TimeOver ? (
            <div className="thanks">
              <h1>Thanks for showing your interest.</h1>
              <p>
                We are closed for this season's recruitment. Keep your eye on
                our{" "}
                <a
                  href="https://www.facebook.com/ewudc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook Page
                </a>{" "}
                for the next recruitment
              </p>
            </div>
          ) : Stepper === 5 ? (
            <div className="thanks">
              <h1>Thanks for showing your interest.</h1>
              <p>
                Next Up: You will be notified about the interview via
                email/call.
              </p>
            </div>
          ) : (
            <form className="" onSubmit={handleSubmit}>
              <h1>Welcome to EWUDC</h1>
              <p>
                Take the first step joining EWU's one of the most prestigious
                club.
              </p>

              {
                {
                  1: (
                    <div className={`step ${FormShow ? "active" : "deactive"}`}>
                      <h2>Let's Start with the basics:</h2>
                      <LegendInput
                        label={"Name:"}
                        value={Name}
                        setValue={setName}
                        placeholder="John Doe"
                        type="text"
                      />
                      <LegendInput
                        label={"ID:"}
                        value={ID}
                        setValue={setID}
                        placeholder="2024-3-60-000"
                        type="text"
                        pattern="\d{4}-\d-\d{2}-\d{3}"
                      />
                      <LegendInput
                        label={"Phone Number:"}
                        value={PhoneNo}
                        setValue={setPhoneNo}
                        placeholder="01xxxxxxxxx"
                        type="tel"
                        pattern="01\d{9}"
                      />
                      <LegendInput
                        label={"Personal Email:"}
                        value={Email}
                        setValue={setEmail}
                        placeholder="john.doe@gmail.com"
                        type="email"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      />
                      <LegendInput
                        label={"Department:"}
                        value={Department}
                        setValue={setDepartment}
                        placeholder="CSE/EEE/BBA etc"
                        type="text"
                      />
                      <LegendInput
                        label={"Current Semester:"}
                        value={Semester}
                        setValue={setSemester}
                        placeholder="First/1st"
                        type="text"
                      />
                      <button
                        type="submit"
                        disabled={
                          !(
                            Name &&
                            ID &&
                            PhoneNo &&
                            Email &&
                            Semester &&
                            Department
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ),
                  2: (
                    <div className={`step ${FormShow ? "active" : "deactive"}`}>
                      <h2>Choose Departments You are Interested In:</h2>
                      <div className="grid odd">
                        {React.Children.toArray(
                          Options.map((op) => (
                            <button
                              className={`option ${
                                Fields.includes(op) ? "selected" : ""
                              }`}
                              type="button"
                              onClick={() => handleClick(op)}
                            >
                              {op}
                            </button>
                          ))
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => setStepper(3)}
                        disabled={!(Fields.length > 0)}
                      >
                        Next
                      </button>
                    </div>
                  ),
                  3: (
                    <div className={`step ${FormShow ? "active" : "deactive"}`}>
                      <h2>General questions:</h2>
                      <LegendTextarea
                        label={`What motivates you to join the debate club, and how do you believe debating can contribute to your personal or academic growth?`}
                        value={Motivation}
                        setValue={setMotivation}
                        required={true}
                      />
                      <h3>Debating Experience (Select One):</h3>
                      <div className="grid">
                        <button
                          type="button"
                          className={`option ${
                            Experience === "Novice" ? "selected" : ""
                          }`}
                          onClick={() => setExperience("Novice")}
                        >
                          Novice
                        </button>
                        <button
                          type="button"
                          className={`option ${
                            Experience === "Experienced Debater"
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => setExperience("Experienced Debater")}
                        >
                          Experienced Debater
                        </button>
                      </div>

                      <h3>Preferred Language for Debating (Select one):</h3>
                      <div className="grid odd">
                        <button
                          type="button"
                          className={`option ${
                            Language === "Bengali" ? "selected" : ""
                          }`}
                          onClick={() => setLanguage("Bengali")}
                        >
                          Bengali
                        </button>
                        <button
                          type="button"
                          className={`option ${
                            Language === "English" ? "selected" : ""
                          }`}
                          onClick={() => setLanguage("English")}
                        >
                          English
                        </button>
                        <button
                          type="button"
                          className={`option ${
                            Language === "Both" ? "selected" : ""
                          }`}
                          onClick={() => setLanguage("Both")}
                        >
                          Both
                        </button>
                      </div>

                      <h3>Are you currently a member of any other club?</h3>
                      <div
                        className={`grid ${
                          ClubAffiliations === "Yes" ? "odd" : ""
                        }`}
                      >
                        <button
                          type="button"
                          className={`option ${
                            ClubAffiliations === "Yes" ? "selected" : ""
                          }`}
                          onClick={() => setClubAffiliations("Yes")}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className={`option ${
                            ClubAffiliations === "No" ? "selected" : ""
                          }`}
                          onClick={() => setClubAffiliations("No")}
                        >
                          No
                        </button>
                        {ClubAffiliations === "Yes" ? (
                          <div className="option blank">
                            <LegendInput
                              label={
                                "Please list the name(s) of the club(s) you are involved with:"
                              }
                              value={Clubs}
                              setValue={setClubs}
                              placeholder=""
                              type="text"
                            />
                          </div>
                        ) : undefined}
                      </div>

                      <button
                        type="button"
                        onClick={() => setStepper(4)}
                        disabled={
                          !(
                            Motivation &&
                            Experience &&
                            Language &&
                            ClubAffiliations
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  ),
                  4: (
                    <div className={`step ${FormShow ? "active" : "deactive"}`}>
                      <h2>Answer the below questions:(Optional)</h2>
                      {Fields?.includes("Debater") ? (
                        <LegendTextarea
                          label={`Why should AI be fully integrated into academic assignments? To what extent should its use be allowed?`}
                          value={Q1 || ""}
                          setValue={setQ1}
                          required={false}
                        />
                      ) : Fields?.includes("Organizer") ? (
                        <LegendTextarea
                          label={`Organizing events requires a substantial time commitment. How will you manage event planning, meetings, and other responsibilities?`}
                          value={Q1 || ""}
                          setValue={setQ1}
                          required={false}
                        />
                      ) : (
                        <>
                          <h3>
                            Do you have previous experience in graphic
                            design?(Optional)
                          </h3>
                          <div className={`grid ${Q1 === "Yes" ? "odd" : ""}`}>
                            <button
                              type="button"
                              className={`option ${
                                Q1 === "Yes" ? "selected" : ""
                              }`}
                              onClick={() => setQ1("Yes")}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`option ${
                                Q1 === "No" ? "selected" : ""
                              }`}
                              onClick={() => setQ1("No")}
                            >
                              No
                            </button>
                            {Q1 === "Yes" ? (
                              <div className="option blank">
                                <LegendInput
                                  label={
                                    "List the softwares you primarily use for graphic design?"
                                  }
                                  value={Q1E}
                                  setValue={setQ1E}
                                  placeholder="PhotoShop, Illustrator, ..."
                                  type="text"
                                />
                              </div>
                            ) : undefined}
                          </div>

                          <h3>
                            Do you have previous experience as a video
                            editor?(Optional)
                          </h3>
                          <div className={`grid ${Q2 === "Yes" ? "odd" : ""}`}>
                            <button
                              type="button"
                              className={`option ${
                                Q2 === "Yes" ? "selected" : ""
                              }`}
                              onClick={() => setQ2("Yes")}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`option ${
                                Q2 === "No" ? "selected" : ""
                              }`}
                              onClick={() => setQ2("No")}
                            >
                              No
                            </button>
                            {Q2 === "Yes" ? (
                              <div className="option blank">
                                <LegendInput
                                  label={
                                    "List the softwares you primarily use for video editing?"
                                  }
                                  value={Q2E}
                                  setValue={setQ2E}
                                  placeholder="Premier Pro, After Effect, Canva, ..."
                                  type="text"
                                />
                              </div>
                            ) : undefined}
                          </div>

                          <h3>
                            Do you have previous experience as a Content
                            Writer?(Optional)
                          </h3>
                          <div className={`grid`}>
                            <button
                              type="button"
                              className={`option ${
                                Q3 === "Yes" ? "selected" : ""
                              }`}
                              onClick={() => setQ3("Yes")}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`option ${
                                Q3 === "No" ? "selected" : ""
                              }`}
                              onClick={() => setQ3("No")}
                            >
                              No
                            </button>
                          </div>
                        </>
                      )}
                      <button type="submit">Submit</button>
                    </div>
                  ),
                }[Stepper]
              }
            </form>
          )}
        </div>
        <footer className="footer">
          <p>
            &copy; EWUDC 2024 | Developed by{" "}
            <a
              href="https://www.facebook.com/AkhlakHossainJim03/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Akhlak
            </a>
          </p>
        </footer>
      </Container>
    </>
  );
}

const Container = styled.main`
  font-family: "Lato", sans-serif;
  min-height: 100dvh;
  width: min(1440px, 100%);
  margin: 0 auto;
  background: url(/media/ewu/fall24/left.svg), url(/media/ewu/fall24/right.svg);
  background-size: 180px auto, 180px auto;
  background-position: top right, bottom left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  & > .body {
    display: flex;
    min-height: calc(100dvh - 48px);
    & > form,
    & > .thanks {
      width: min(600px, 100%);
      margin: 62px auto 48px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      justify-content: center;
      text-align: center;
      & > .step {
        padding-top: 32px;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all 300ms ease-in-out;
        &.deactive {
          display: none;
          height: 0px;
          overflow: hidden;
        }
        & > h2,
        & > h3 {
          text-align: left;
          width: 100%;
        }
        & > .grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          width: 100%;
          & > .option {
            border: 2px solid #cccccc;
            background-color: color-mix(in srgb, #cccccc 40%, #ffffff00);
            padding: 12px;
            border-radius: 8px;
            font-weight: 500;
            &.selected {
              background-color: color-mix(in srgb, #fbdc1a 80%, #ffffff00);
              border-color: #fbdc1a;
            }
            &.blank {
              border: none;
              background-color: transparent;
              padding: 0;
              border-radius: 0;
              font-weight: 400;
            }
          }
          &.odd {
            & > .option {
              &:last-child {
                grid-column: 1 / span 2;
              }
            }
          }
        }
        & > button {
          padding: 12px 36px;
          width: 100%;
          border: none;
          outline: none;
          background: #fbdc1a; //yellow
          font-size: 1.1rem;
          font-weight: 500;
          border-radius: 8px;
          &:disabled {
            opacity: 0.6;
          }
        }
      }
    }
  }
  & > .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    padding: 12px 24px;
    & > p {
      & > a {
        font-weight: 600;
        font-style: italic;
        color: inherit;
      }
    }
  }
`;
