import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { collection, onSnapshot, query } from "firebase/firestore";
import { auth, db } from "../../utils/firebase";
import { signOut } from "firebase/auth";

export default function EWUDCFall2024() {
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [Error, setError] = useState();
  const [LoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    let user = sessionStorage.user;
    user && JSON.parse(user).uid && setLoggedIn(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    Email &&
      Password &&
      signInWithEmailAndPassword(auth, Email, Password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          setLoggedIn(true);
          sessionStorage.user = JSON.stringify(user);
        })
        .catch((error) => {
          setError(error);
          console.error(error);
        });
  };
  return LoggedIn ? (
    <>
      <DashBoard />
    </>
  ) : (
    <Container>
      <div className="container">
        <h1>Sign in to you account.</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Enter your email: *</label>
          <input
            type="email"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            id="email"
            required
          />
          <label htmlFor="password">Enter your Password: *</label>
          <input
            type="password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            id="password"
            required
          />
          {Error && <p className="error">{Error.message}</p>}
          <button type="submit">Sign In</button>
        </form>
      </div>
    </Container>
  );
}

function DashBoard() {
  const [UserData, setUserData] = useState([]);
  const [Data, setData] = useState([]);

  const keys = [
    "name",
    "iD",
    "contact",
    "email",
    "department",
    "semester",
    "departments",
    "motivation",
    "experience",
    "language",
    "clubAffiliation",
    "otherClubs",
    "q1",
    "q2",
    "q3",
  ];

  function SignOut() {
    signOut(auth)
      .then(() => {
        sessionStorage.removeItem("user");
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (sessionStorage.user) {
      let data = JSON.parse(sessionStorage.user);
      setUserData(data);
      let arr = [];
      const qu = query(collection(db, "EWUDC-Fall-2024"));
      onSnapshot(qu, (snapshot) => {
        snapshot.forEach((item) => {
          if (item.type === "removed") {
            arr = [...arr.filter((el) => el.id !== item.id)];
          } else {
            arr.push({ id: item.id, ...item.data() });
          }
        });
        setData(arr);
      });
    } else {
      sessionStorage.removeItem("user");
      window.location.reload();
    }
  }, []);

  return (
    <DashBoardStyle>
      <div className="data">
        <div className="stat">
          <div className="card_stat total">
            <h5>Total Responses</h5>
            <h2>{Data.length}</h2>
          </div>
          <div className="card_stat other">
            <h5>Experienced Debater</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.experience === "Experienced Debater")
                  .length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.experience === "Experienced Debater")
                    .length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
          <div className="card_stat other">
            <h5>Interested in Debate</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.typeAnswered === "Debater").length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.typeAnswered === "Debater").length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
          <div className="card_stat other">
            <h5>Interested in Organizing</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.typeAnswered === "Organizer").length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.typeAnswered === "Organizer").length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
          <div className="card_stat other">
            <h5>Interested in Creatives</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.typeAnswered === "Creatives").length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.typeAnswered === "Creatives").length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
        </div>

        <br />
        <h3>Beautified Data</h3>
        {Array.isArray(Data) && Data.length > 0 && (
          <div className="sheet">
            <table>
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>
                    Name
                    <br />
                    (ID)
                  </th>
                  <th>Contact</th>
                  <th>
                    Department
                    <br />
                    Semester
                  </th>
                  <th>Interested Being</th>
                  <th>Debating Level</th>
                  <th>Language</th>
                  <th>Other Clubs</th>
                  <th colSpan={2}>Motivates</th>
                  <th style={{ minWidth: "350px" }}>Q1</th>
                  <th>Q2</th>
                  <th>Q3</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  Data.map((data, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {data?.name}
                        <br />
                        {data?.iD}
                      </td>
                      <td>
                        <a href={`mailto:${data?.email}`}>{data?.email}</a>
                        <br />
                        <a href={`tel:+88${data?.contact}`}>{data?.contact}</a>
                      </td>
                      <td>
                        {data?.department}-
                        <br />
                        <em>{data?.semester}</em>
                      </td>
                      <td>{data?.departments?.join(", ")}</td>
                      <td>{data?.experience}</td>
                      <td>{data?.language}</td>
                      <td>
                        {data?.clubAffiliation
                          ? `${data?.clubAffiliation}${
                              data?.otherClubs ? ": " + data?.otherClubs : ""
                            }`
                          : ""}
                      </td>
                      <td colSpan={2}>{data?.motivation}</td>
                      <td style={{ minWidth: "350px" }}>
                        <strong>
                          {data?.typeAnswered === "Debater"
                            ? "Why should AI be fully integrated into academic assignments? To what extent should its use be allowed?"
                            : data?.typeAnswered === "Organizer"
                            ? "Organizing events requires a substantial time commitment. How will you manage event planning, meetings, and other responsibilities?"
                            : "Do you have previous experience in graphic design?(Optional)"}
                        </strong>
                        <br />
                        <em>
                          {data?.q1 ? data?.q1 : "Not Answered"}
                          {data?.q1e ? ": " + data?.q1e : ""}
                        </em>
                      </td>
                      <td>
                        {data?.typeAnswered === "Creatives" ? (
                          <>
                            <strong>
                              Do you have previous experience as a video
                              editor?(Optional)
                            </strong>
                            <br />
                            <em>
                              {data?.q2}
                              {data?.q2e ? ": " + data?.q2e : ""}
                            </em>
                          </>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td>
                        {data?.typeAnswered === "Creatives" ? (
                          <>
                            <strong>
                              Do you have previous experience as a Content
                              Writer?(Optional)
                            </strong>
                            <br />
                            <em>{data?.q3}</em>
                          </>
                        ) : (
                          "--"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}

        {UserData?.uid === "NfDHCYgalUTaQg0iEVvJeY5VnmF2" && (
          <>
            <h3>Raw Data:</h3>
            {Array.isArray(Data) && Data.length > 0 && (
              <div className="sheet">
                <table>
                  <thead>
                    <tr>
                      <th>Sr</th>
                      {React.Children.toArray(
                        keys.map((el) => (
                          <th style={{ textTransform: "capitalize" }}>{el}</th>
                        ))
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      Data.map((data, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          {React.Children.toArray(
                            keys.map((k) =>
                              k === "departments" ? (
                                <td>{data?.[k]?.join(", ")}</td>
                              ) : k === "contact" ? (
                                <td>'+88{data?.[k]}</td>
                              ) : k === "q1" ? (
                                <td>
                                  <strong>
                                    {data?.typeAnswered === "Debater"
                                      ? "Why should AI be fully integrated into academic assignments? To what extent should its use be allowed?"
                                      : data?.typeAnswered === "Organizer"
                                      ? "Organizing events requires a substantial time commitment. How will you manage event planning, meetings, and other responsibilities?"
                                      : "Do you have previous experience in graphic design?(Optional)"}
                                  </strong>
                                  <br />
                                  <em>
                                    {data?.q1 ? data?.q1 : "Not Answered"}
                                    {data?.q1e ? ": " + data?.q1e : ""}
                                  </em>
                                </td>
                              ) : k === "q2" ? (
                                <td>
                                  {data?.typeAnswered === "Creatives" ? (
                                    <>
                                      <strong>
                                        Do you have previous experience as a
                                        video editor?(Optional)
                                      </strong>
                                      <br />
                                      <em>
                                        {data?.q2}
                                        {data?.q2e ? ": " + data?.q2e : ""}
                                      </em>
                                    </>
                                  ) : (
                                    "--"
                                  )}
                                </td>
                              ) : k === "q3" ? (
                                <td>
                                  {data?.typeAnswered === "Creatives" ? (
                                    <>
                                      <strong>
                                        Do you have previous experience as a
                                        Content Writer?(Optional)
                                      </strong>
                                      <br />
                                      <em>{data?.q3}</em>
                                    </>
                                  ) : (
                                    "--"
                                  )}
                                </td>
                              ) : (
                                <td>{data?.[k]}</td>
                              )
                            )
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </DashBoardStyle>
  );
}

const DashBoardStyle = styled.main`
  --pink: #e9bfde;
  --green: #d2e1b4;
  --blue: #d8f1ff;
  --yellow: #e1dbb4;

  width: 100%;
  overflow-x: hidden;
  & > .data {
    padding: 24px;
    & > p {
      text-align: center;
      font-weight: 600;
    }
    & > .stat {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
      gap: 12px;
      font-size: 125%;
      & > .card_stat {
        padding: 24px;
        border-radius: 8px;
        &.total {
          background-color: var(--green);
        }
        &.male {
          background-color: var(--blue);
        }
        &.female {
          background-color: var(--pink);
        }
        &.other {
          background-color: var(--yellow);
        }
      }
    }
    & > .sheet {
      margin: 24px 0;
      width: 100%;
      overflow-x: auto;
      & > table {
        background-color: rgba(0, 0, 0, 0.3);
        & > * > * > * {
          padding: 6px;
          background-color: #fff;
          max-width: 300px;
          text-align: center;
        }
      }
    }
  }
`;

const Container = styled.main`
  display: flex;
  padding: 24px;
  min-height: 100dvh;
  background-color: #fff;
  color: #000;
  & > .container {
    width: min(100%, 500px);
    margin: auto;
    & > form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 24px 0;
      & > label {
        font-size: 1.2rem;
      }
      & > input {
        padding: 6px 12px;
        font-size: 1.2rem;
        font-weight: 500;
        border: 2px solid #000;
        border-radius: 8px;
      }
      & > button {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 6px 12px;
        border: 2px solid #000;
        border-radius: 8px;
        outline: none;
        background-color: transparent;
        color: #000;
        &:hover,
        &:focus {
          color: #fff;
          background-color: #000;
        }
      }
    }
  }
`;
